import { PieChartOutlined, TableOutlined } from '@ant-design/icons';
import { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectRoleOptions,
  selectUser,
} from '../../features/authentication/redux/selectors';
import PortfolioGrowthPlan from '../../features/lead/portfolio_growth_plan/PortfolioGrowthPlan';
import { OffsetAccountSummary } from '../../features/offset_account/redux/types';
import Property from '../../features/property/Property';
import {
  PlanRecords,
  PropertyListItem,
} from '../../features/property/redux/types';
import House from '../assets/svgs/House';
import Sliders from '../assets/svgs/Sliders';
import { useRole } from '../cutom_hooks/useRole';
import { useViewport } from '../cutom_hooks/useViewport';

const Agency = lazy(() => import('../../features/agency/Agency'));
const Client = lazy(() => import('../../features/client/Client'));
const Dashboard = lazy(() => import('../../features/dashboard/Dashboard'));
const Enterprise = lazy(() => import('../../features/enterprise/Enterprise'));
const EnterpriseDetail = lazy(
  () => import('../../features/enterprise/enterprise_detail/EnterpriseDetail'),
);
const Focus = lazy(() => import('../../features/focus/Focus'));
const AnnualFigures = lazy(
  () => import('../../features/property/annual_figures/AnnualFigures'),
);
const MonthlyModeling = lazy(
  () => import('../../features/monthly_modeling/MonthlyModeling'),
);
const CashRequiredEstimates = lazy(
  () =>
    import(
      '../../features/property/cash_required_estimates/CashRequiredEstimates'
    ),
);

const Info = lazy(() => import('../../features/property/info/Info'));
const AgencyDetail = lazy(
  () => import('../../features/agency/agency_detail/AgencyDetail'),
);
const ReportAccess = lazy(
  () => import('../../features/ai_report/report_access/ReportAccess'),
);
const Plan = lazy(() => import('../../features/plan/Plan'));
const SuburbScoring = lazy(
  () => import('../../features/suburb_scoring/SuburbScoring'),
);
const Inputs = lazy(() => import('../../features/user_inputs/UserInput'));
const SettlementPeriod = lazy(
  () => import('../../features/settlement_period/SettlementPeriod'),
);
const PurchaseScenario = lazy(
  () => import('../../features/purchase_scenario/PurchaseScenario'),
);
const PropertyType = lazy(
  () => import('../../features/property_type/PropertyType'),
);
const ContactUs = lazy(
  () => import('../../features/support/contact_us/ContactUs'),
);
const AiReport = lazy(() => import('../../features/ai_report/AiReport'));
const BookACall = lazy(
  () => import('../../features/lead/book_a_call/BookACall'),
);
const Lead = lazy(() => import('../../features/lead/Lead'));
const OffsetAccount = lazy(
  () => import('../../features/offset_account/OffsetAccount'),
);
const StampDutyLMI = lazy(
  () => import('../../features/stamp_duty_lmi/StampDutyLMI'),
);
const ClientRiskProfile = lazy(
  () =>
    import(
      '../../features/standard_tables/client_risk_profile/ClientRiskProfile'
    ),
);
const InterestRateProjection = lazy(
  () =>
    import(
      '../../features/standard_tables/inrerest_rate_projection/InterestRateProjection'
    ),
);

const headerTitle = `Portfolio Planner`;

export type RouteType = {
  path: string;
  name: string;
  component?: any;
  icon?: any;
  routes?: RouteType[];
  displayHeader?: boolean;
  exact?: boolean;
  strict?: boolean;
  isSubMenu?: boolean;
  hideInMenu?: boolean;
  headerTitle?: string;
  headerSubTitle?: string;
  displaySidebar?: boolean;
  isPublic?: boolean;
  completePath: string;
  data?: any;
};

export const useRoutes = (
  properties: PropertyListItem[] | undefined,
  offsetAccounts: OffsetAccountSummary[] | undefined,
  planRecords: PlanRecords[] | undefined,
) => {
  const ROLES_IDS = useSelector(selectRoleOptions);
  const user = useSelector(selectUser);

  const { isDesktopViewport } = useViewport();
  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const [dynamicRoutes, setDynamicRoutes] = useState<RouteType[]>();
  const [routes, setRoutes] = useState<RouteType[]>([]);

  const createPropertyRoutes = (
    item: any,
    i: number,
    propertiesLength: number,
    isDesktopViewport: boolean,
  ) => {
    const propertyRoutes: any = [
      {
        path: '/properties/:id/info',
        name: 'Info',
        headerSubTitle: '',
        component: Info,
        exact: true,
        strict: true,
        displayHeader: false,
        headerTitle: item.headerTitle,
        displaySidebar: true,
        completePath: `/properties/${item.propertyId}/info`,
        data: {
          isLastProperty:
            propertiesLength === 1 ? false : i + 1 === propertiesLength,
          isFirstProperty: i === 0,
          numberOfProperties: propertiesLength,
          propertyIndex: i,
          propertyId: item.propertyId,
        },
      },
    ];

    if (item.purchaseType !== 'Existing') {
      propertyRoutes.push(
        {
          path: '/properties/:id/cash-req-estimates',
          name: 'Cash Required Estimates',
          headerSubTitle: 'Standard Properties',
          component: CashRequiredEstimates,
          exact: true,
          strict: true,
          displayHeader: false,
          headerTitle: item.headerTitle,
          displaySidebar: true,
          completePath: `/properties/${item.propertyId}/cash-req-estimates`,
          data: {
            propertyId: item.propertyId,
          },
        },
        {
          path: '/properties/:id/purchase-scenario',
          name: 'Purchase Scenario',
          headerSubTitle: 'Standard Properties',
          component: PurchaseScenario,
          exact: true,
          strict: true,
          displayHeader: false,
          headerTitle: item.headerTitle,
          displaySidebar: true,
          hideInMenu: i === 0,
          completePath: `/properties/${item.propertyId}/purchase-scenario`,
          data: {
            propertyId: item.propertyId,
          },
        },
      );
    }

    propertyRoutes.push(
      {
        path: '/properties/:id/annual-figures',
        name: 'Annual Figures',
        headerSubTitle: 'Standard Properties',
        component: AnnualFigures,
        exact: true,
        strict: true,
        displayHeader: false,
        headerTitle: item.headerTitle,
        displaySidebar: true,
        completePath: `/properties/${item.propertyId}/annual-figures`,
        data: {
          propertyId: item.propertyId,
        },
      },
      {
        path: '/properties/:id/monthly-modeling',
        name: 'Financial Modeling',
        headerSubTitle: 'Standard Properties',
        component: MonthlyModeling,
        exact: true,
        strict: true,
        displayHeader: false,
        headerTitle: item.headerTitle,
        displaySidebar: true,
        completePath: `/properties/${item.propertyId}/monthly-modeling`,
        data: {
          propertyId: item.propertyId,
        },
      },
    );

    return {
      path: '/properties/:id',
      name: item.propertyName,
      headerSubTitle: 'Standard Properties',
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: item.headerTitle,
      icon: isDesktopViewport ? undefined : House,
      displaySidebar: true,
      isSubMenu: true,
      routes: propertyRoutes,
      completePath: `/properties/${item.propertyId}`,
      data: {
        isSold: item.isSold,
        isProperty: true,
      },
    };
  };

  const createOffsetAccountRoute = (item: any) => ({
    path: '/oa-transactions/:id',
    name: `Offset Account ${item.transactionDate}`,
    headerSubTitle: '',
    component: OffsetAccount,
    exact: true,
    strict: true,
    displayHeader: false,
    headerTitle: `Offset Account ${item.transactionDate}`,
    displaySidebar: true,
    completePath: `/oa-transactions/${item.offsetAccountId}`,
    data: {
      offsetAccountId: item.offsetAccountId,
    },
  });

  const createDynamicRoutes = () => {
    const newRoutes: any = [];

    if (planRecords) {
      let i = 0;

      for (const planRecord of planRecords) {
        if (planRecord.type === 'Property' && properties) {
          for (const item of properties) {
            if (item.propertyId === planRecord.recordId) {
              newRoutes.push(
                createPropertyRoutes(
                  item,
                  i,
                  properties.length,
                  isDesktopViewport,
                ),
              );
              break;
            }
          }
          i++;
        }

        if (
          !isClientView &&
          !isAgencyLeadView &&
          planRecord.type === 'OffsetAccount' &&
          offsetAccounts
        ) {
          for (const item of offsetAccounts) {
            if (item.offsetAccountId === planRecord.recordId) {
              newRoutes.push(createOffsetAccountRoute(item));
              break;
            }
          }
        }
      }

      setDynamicRoutes(newRoutes);
    } else {
      setDynamicRoutes([]);
    }
  };

  const standardTablesRouteList = {
    propertyType: {
      path: '/property-type',
      name: 'Property Type',
      headerSubTitle: 'Property Types',
      component: PropertyType,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
      completePath: '/property-type',
    },
    costByStateLMI: {
      path: '/cost-by-state-lmi',
      completePath: '/cost-by-state-lmi',
      name: 'Costs by State & LMI',
      headerSubTitle: 'Lender Mortgage Insurance',
      component: StampDutyLMI,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
    },
    settlementPeriod: {
      path: '/settlement_period',
      completePath: '/settlement_period',
      name: 'Settlement Period',
      headerSubTitle: 'Settlement Period',
      component: SettlementPeriod,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
    },
    focus: {
      path: '/focus',
      completePath: '/focus',
      name: 'Focus',
      headerSubTitle: 'Focus',
      component: Focus,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
    },
    riskProfile: {
      path: '/risk-profile',
      completePath: '/risk-profile',
      name: 'Client Risk Profile',
      headerSubTitle: 'Client Risk Profile',
      component: ClientRiskProfile,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
    },
    interestRate: {
      path: '/interest-rate',
      completePath: '/interest-rate',
      name: 'Interest Rate Assumptions',
      headerSubTitle: 'Interest Rate Assumptions',
      component: InterestRateProjection,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle: `Standard Tables`,
      displaySidebar: true,
    },
  };

  const propertiesRouteList = {
    propertiesNew: {
      path: '/properties/new',
      name: 'Add New Property',
      headerSubTitle: 'Add New Property',
      component: Property,
      exact: true,
      strict: true,
      displayHeader: true,
      headerTitle,
      displaySidebar: true,
      completePath: `/properties/new`,
      hideInMenu:
        properties && properties[properties.length - 1]?.isActive === false,
    },
    offsetAccountTransactionsNew: {
      path: '/oa-transactions/new',
      name: 'Add New Offset Account Transactions',
      headerSubTitle: 'Add New Offset Account Transactions',
      component: OffsetAccount,
      exact: true,
      strict: true,
      displayHeader: false,
      headerTitle,
      displaySidebar: true,
      completePath: `/oa-transactions/new`,
      hideInMenu:
        (properties && properties[0]?.isActive === false) ||
        (offsetAccounts &&
          offsetAccounts[offsetAccounts.length - 1]?.isActive === false),
    },
  };

  const mainRouteList = {
    indexSuperAdmin: {
      path: '/',
      name: 'Enterprise',
      component: Enterprise,
      exact: true,
      strict: true,
      displayHeader: true,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/',
    },
    indexEnterpriseAdmin: {
      path: '/',
      name: 'Agency',
      component: Agency,
      exact: true,
      strict: true,
      displayHeader: true,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/',
    },
    indexAgencyAdmin: {
      path: '/',
      name: 'Client',
      component: Client,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/',
    },
    indexAgencyUser: {
      path: '/',
      name: 'Client',
      component: Client,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/',
    },
    indexLead: {
      path: '/',
      name: 'Lead Landing',
      component: Lead,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/',
    },
    indexClient: {
      path: '/',
      name: 'Client',
      component: Plan,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/',
    },
    plan: {
      path: '/plan',
      name: 'Plan',
      component: Plan,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/plan',
    },
    dashboard: {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      icon: PieChartOutlined,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: true,
      completePath: '/dashboard',
    },
    inputs: {
      name: 'Your Inputs',
      icon: Sliders,
      isSubMenu: false,
      path: '/inputs',
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: true,
      component: Inputs,
      completePath: '/inputs',
    },
    aiReportInternal: {
      path: '/remi-internal/report/:suburbCodeParam',
      name: 'AI Report Internal',
      component: AiReport,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/remi-internal/report/:suburbCodeParam',
    },
    aiSharedReport: {
      path: '/remi/shared-report/:reportCodeParam',
      name: 'Client Access',
      component: ReportAccess,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/remi/shared-report/:reportCodeParam',
    },
    enterpriseSettings: {
      path: '/enterprise-settings',
      name: 'Enterprise Detail',
      component: EnterpriseDetail,
      exact: true,
      strict: true,
      displayHeader: true,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/enterprise-settings',
    },
    agency: {
      path: '/agency/:id',
      name: 'Agency Detail',
      component: AgencyDetail,
      exact: true,
      strict: true,
      displayHeader: true,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/agency/:id',
    },
    leadLanding: {
      path: '/lead-landing',
      name: 'Lead Landing',
      component: Lead,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/lead-landing',
    },
    remi: {
      path: '/remi',
      name: 'REMi',
      component: SuburbScoring,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/remi',
    },
    aiReport: {
      path: '/remi/report/:suburbCodeParam',
      name: 'AI Report',
      component: AiReport,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/remi/report/:suburbCodeParam',
    },
    portfolioGrowthPlan: {
      path: '/portfolio-growth-plan',
      name: 'Portfolio Growth Plan',
      component: PortfolioGrowthPlan,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/portfolio-growth-plan',
    },
    bookACall: {
      path: '/book-a-call',
      name: 'Book A Call',
      component: BookACall,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/book-a-call',
    },
    contactUs: {
      path: '/contact-us',
      name: 'Contact Us',
      component: ContactUs,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/contact-us',
    },
    remiInternal: {
      path: '/remi-internal',
      name: 'REMi Internal',
      component: SuburbScoring,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      displaySidebar: false,
      hideInMenu: true,
      completePath: '/remi-internal',
    },
    standardTables: {
      name: 'Standard Tables',
      icon: TableOutlined,
      isSubMenu: true,
      path: 'st',
      displaySidebar: true,
      completePath: 'st',
      hideInMenu: false,
      /* Standard table routes as an array will be set here */
      routes: undefined,
    },
    properties: {
      name: 'Portfolio Planner',
      icon: House,
      isSubMenu: true,
      path: '/properties',
      completePath: '/properties',
      displaySidebar: true,
      hideInMenu: false,
      /* Dynamic properties routes as an array will be set here */
      routes: undefined,
    },
  };

  useEffect(() => {
    if (ROLES_IDS) {
      createDynamicRoutes();
    }
  }, [isDesktopViewport, properties, planRecords, offsetAccounts, ROLES_IDS]);

  useEffect(() => {
    const { propertiesNew, offsetAccountTransactionsNew } = propertiesRouteList;

    const {
      propertyType,
      costByStateLMI,
      settlementPeriod,
      focus,
      riskProfile,
      interestRate,
    } = standardTablesRouteList;

    const {
      indexSuperAdmin,
      indexEnterpriseAdmin,
      indexAgencyAdmin,
      indexAgencyUser,
      indexLead,
      indexClient,
      agency,
      contactUs,
      enterpriseSettings,
      aiReportInternal,
      remiInternal,
      plan,
      dashboard,
      inputs,
      leadLanding,
      aiReport,
      remi,
      portfolioGrowthPlan,
      bookACall,
      aiSharedReport,
      standardTables,
      properties,
    } = mainRouteList;

    const routeMapping = {
      /*
       * Super admin routes
       */
      superAdmin: [indexSuperAdmin, contactUs],

      /*
       * Enterprise admin routes
       */
      enterpriseAdmin: [
        indexEnterpriseAdmin,
        agency,
        contactUs,
        enterpriseSettings,
      ],

      /*
       * Agency admin routes
       * - Properties routes
       *   - New property
       *   - New offset account transactions
       * - Standard tables routes
       */
      agencyAdmin: [
        indexAgencyAdmin,
        aiReportInternal,
        remiInternal,
        plan,
        dashboard,
        inputs,
        agency,
        contactUs,
        {
          ...properties,
          routes: [
            propertiesNew,
            offsetAccountTransactionsNew,
            ...(dynamicRoutes ?? []),
          ],
        },
        {
          ...standardTables,
          routes: [
            propertyType,
            costByStateLMI,
            settlementPeriod,
            focus,
            riskProfile,
            interestRate,
          ],
        },
      ],

      /*
       * Agency user routes
       * - Properties routes
       *   - New property
       *   - New offset account transactions
       * - Standard tables routes
       */
      agencyUser: [
        indexAgencyUser,
        aiReportInternal,
        remiInternal,
        plan,
        dashboard,
        inputs,
        contactUs,
        {
          ...properties,
          routes: [
            propertiesNew,
            offsetAccountTransactionsNew,
            ...(dynamicRoutes ?? []),
          ],
        },
        {
          ...standardTables,
          routes: [
            propertyType,
            costByStateLMI,
            settlementPeriod,
            focus,
            riskProfile,
            interestRate,
          ],
        },
      ],

      /*
       * Agency lead routes
       * - Properties routes
       */
      agencyLead: [
        indexLead,
        leadLanding,
        aiReport,
        remi,
        portfolioGrowthPlan,
        bookACall,
        dashboard,
        inputs,
        contactUs,
        {
          ...properties,
          routes: dynamicRoutes,
        },
      ],

      /*
       * Client routes
       * - Properties routes
       */
      client: [
        indexClient,
        aiReportInternal,
        plan,
        dashboard,
        inputs,
        aiSharedReport,
        contactUs,
        remiInternal,
        {
          ...properties,
          routes: dynamicRoutes,
        },
      ],
    };

    if (ROLES_IDS && dynamicRoutes) {
      const userRole = Object.keys(ROLES_IDS).find(
        (key) => ROLES_IDS[key] === user?.role_id,
      );

      if (userRole) {
        /* Finally, set the routes based on the user role */
        setRoutes(routeMapping[userRole]);
      }
    }
  }, [dynamicRoutes]);

  return routes;
};
