import { createSlice } from '@reduxjs/toolkit';

import { fetchFormValues } from '../../property/redux/async_thunks';
import { suburbScoreSearch, suburbScoreSuggestions } from './async_thunks';
import { SearchState } from './types';

const minMaxDefault: any = {
  min: null,
  max: null,
};

export const searchMetricsDefaults = {
  growthScore: minMaxDefault,
  medianSalePrice: minMaxDefault,
  medianSalePriceGrowthForecast15Mo: minMaxDefault,
  cashFlowScore: minMaxDefault,
  estNetRentalYield: minMaxDefault,
  medianRentalGrowthForecast15Mo: minMaxDefault,
  affordabilityScore: minMaxDefault,
  socioEconomicScore: minMaxDefault,
  population: minMaxDefault,
  populationGrowthForecast5Yr: minMaxDefault,
  lgaPopulationGrowthForecast5Yr: minMaxDefault,
  grossRentalYield: minMaxDefault,
};

const initialState: SearchState = {
  searchMetricValues: {
    filters: [],
    sortTypes: [],
  },
  isSearchFeatureLoading: false,
  options: {
    pageIndex: 1,
    sortType: 'medianSalePrice',
    orderAsc: false,
    searchMetrics: searchMetricsDefaults,
    categoryId: 0,
  },
  suggestions: [],
  queryValues: [],
  enabledSearchMetrics: [],
  keyword: undefined,
  results: undefined,
  lastSearchCategoryIdSetting: 0,
};

export const suburbScoringSlice = createSlice({
  name: 'suburb_scoring',
  initialState,
  reducers: {
    setIsSearchFeatureLoading: (state, action) => {
      state.isSearchFeatureLoading = action.payload;
    },
    setOptions: (state, action) => {
      state.options = {
        ...action.payload,
      };
    },
    resetSearch: (state) => {
      state.suggestions = initialState.suggestions;
      state.results = initialState.results;
      state.enabledSearchMetrics = initialState.enabledSearchMetrics;
      state.keyword = initialState.keyword;
      state.queryValues = initialState.queryValues;

      state.options = {
        ...state.options,
        ...initialState.options,
        searchMetrics: initialState.options.searchMetrics,
      };

      state.lastSearchCategoryIdSetting =
        initialState.lastSearchCategoryIdSetting;
    },
    setQueryValues: (state, action) => {
      state.queryValues = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setEnabledSearchMetrics: (state, action) => {
      state.enabledSearchMetrics = action.payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = initialState.suggestions;
    },
    resetResults: (state) => {
      state.results = initialState.results;
    },
    setLastSearchCategoryIdSetting: (state, action) => {
      state.lastSearchCategoryIdSetting = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFormValues.fulfilled, (state, action) => {
      state.searchMetricValues = action.payload.suburbScoreFilters;
    });

    builder.addCase(suburbScoreSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });

    builder.addCase(suburbScoreSuggestions.rejected, (state) => {
      state.suggestions = initialState.suggestions;
    });

    builder.addCase(suburbScoreSearch.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.results = {
          suburbs: action.payload.suburbs,
          pageCount:
            action.payload.pageCount || action.payload.pageCount === 0
              ? action.payload.pageCount
              : state.results.pageCount,
          resultCount:
            action.payload.resultCount || action.payload.resultCount === 0
              ? action.payload.resultCount
              : state.results.resultCount,
          itemsPerPage: action.payload.itemsPerPage,
          descriptors: action.payload.descriptors,
          suburbWarning: action.payload.suburbWarning,
        };

        if (action.payload?.pageIndex) {
          state.options = {
            ...state.options,
            pageIndex: action.payload.pageIndex,
          };
        }
      } else {
        state.results = initialState.results;
      }
    });

    builder.addCase(suburbScoreSearch.rejected, (state) => {
      state.results = initialState.results;
    });
  },
});

export const {
  setKeyword,
  setEnabledSearchMetrics,
  setOptions,
  resetSuggestions,
  setQueryValues,
  resetResults,
  resetSearch,
  setIsSearchFeatureLoading,
  setLastSearchCategoryIdSetting,
} = suburbScoringSlice.actions;

export default suburbScoringSlice.reducer;
