import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';

export const fetchPropertyNotes: any = createAsyncThunk(
  'property/fetch-property-notes',
  async (apiParams: { token: string; propertyId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Info/notes/${apiParams.propertyId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      notes: data.notes,
      contractDate: data.contractDate,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
    };
  },
);

export const updatePropertyNotes: any = createAsyncThunk(
  'property/update-notes',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Info/notes`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      notes: data.notes,
      contractDate: data.contractDate,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
    };
  },
);
