import { notification } from 'antd';
import React, { useEffect } from 'react';

import { User } from '../../features/authentication/redux/types';

const useLeadSuccessMessage = (user?: User) => {
  useEffect(() => {
    if (!user) {
      return;
    }

    const successKey = 'lead_signup_success_campaign';
    const messageKey = 'lead_signup_password_message';

    const successCampaign = sessionStorage.getItem(successKey);

    const isShowPasswordIsInEmailMessage =
      sessionStorage.getItem(messageKey) === '1';

    if (typeof successCampaign === 'string' && !!successCampaign) {
      const duration = isShowPasswordIsInEmailMessage ? 7 : 4.5;
      const description = isShowPasswordIsInEmailMessage ? (
        <>
          Sign-up completed! You are now logged in.
          <br />
          Please check your email for your account password.
        </>
      ) : (
        <>Registration completed! You are now logged in.</>
      );

      notification.success({
        message: 'Success!',
        description,
        placement: 'top',
        duration,
        style: { width: '420px' },
      });

      sessionStorage.removeItem(successKey);
      sessionStorage.removeItem(messageKey);
    }
  }, [user]);
};

export default useLeadSuccessMessage;
