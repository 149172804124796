import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';
import {
  setPlanOffsetAccounts,
  setPlanProperties,
  setPlanRecords,
} from '../../property/redux/slice';

export const fetchPurchaseScenario: any = createAsyncThunk(
  'property/fetch-purchase-scenario',
  async (apiParams: { token: string; propertyId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/PurchaseScenario/${apiParams.propertyId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      fundsRequired: data.purchaseScenarioInfo.fundsRequired,
      numberOfMonths: data.purchaseScenarioInfo.numberOfMonths,
      purchaseDate: data.purchaseScenarioInfo.purchaseDate,
      leverageCash: data.purchaseScenarioInfo.leverageCash,
      leverageEquity: data.purchaseScenarioInfo.leverageEquity,
      shortfallAmount: data.purchaseScenarioInfo.shortfallAmount,
      summary: data.purchaseScenarioInfo.summary,
      isPassed: data.purchaseScenarioInfo.isPassed,
      isRedStatus: data.purchaseScenarioInfo.isRedStatus,
      propertyName: data.propertyName,
      isSold: data.isSold,
      propertyId: data.propertyId,
      equityRedrawLoanOptions: data.equityRedrawLoanOptions,
      loanTypeList: data.loanTypeValues.loanTypeList,
    };
  },
);

export const updateLeverageEquity: any = createAsyncThunk(
  'property/update-leverage-equity',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/PurchaseScenario/equity-leverage`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      fundsRequired: data.purchaseScenarioInfo.fundsRequired,
      numberOfMonths: data.purchaseScenarioInfo.numberOfMonths,
      purchaseDate: data.purchaseScenarioInfo.purchaseDate,
      leverageCash: data.purchaseScenarioInfo.leverageCash,
      shortfallAmount: data.purchaseScenarioInfo.shortfallAmount,
      leverageEquity: data.purchaseScenarioInfo.leverageEquity,
      summary: data.purchaseScenarioInfo.summary,
      isPassed: data.purchaseScenarioInfo.isPassed,
      isRedStatus: data.purchaseScenarioInfo.isRedStatus,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      isSold: data.isSold,
      equityRedrawLoanOptions: data.equityRedrawLoanOptions,
      loanTypeList: data.loanTypeValues.loanTypeList,
    };
  },
);

export const updateLeverageCash: any = createAsyncThunk(
  'property/update-leverage-cash',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/PurchaseScenario/cash-leverage`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      fundsRequired: data.purchaseScenarioInfo.fundsRequired,
      numberOfMonths: data.purchaseScenarioInfo.numberOfMonths,
      purchaseDate: data.purchaseScenarioInfo.purchaseDate,
      leverageCash: data.purchaseScenarioInfo.leverageCash,
      shortfallAmount: data.purchaseScenarioInfo.shortfallAmount,
      leverageEquity: data.purchaseScenarioInfo.leverageEquity,
      summary: data.purchaseScenarioInfo.summary,
      isPassed: data.purchaseScenarioInfo.isPassed,
      isRedStatus: data.purchaseScenarioInfo.isRedStatus,
      propertyName: data.propertyName,
      isSold: data.isSold,
      propertyId: data.propertyId,
      equityRedrawLoanOptions: data.equityRedrawLoanOptions,
      loanTypeList: data.loanTypeValues.loanTypeList,
    };
  },
);

export const updatePurchaseScenarioMonth: any = createAsyncThunk(
  'property/update-purchase-scenario-month',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/PurchaseScenario/months`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    /**
     * Following three updates will refresh the navigation menu.
     */

    // Update Plan records
    thunkAPI.dispatch(setPlanRecords(data.planRecords));

    // Update Offset Accounts
    thunkAPI.dispatch(setPlanOffsetAccounts(data.offsetAccounts));

    // Update plan properties.
    thunkAPI.dispatch(setPlanProperties(data.planPropertyIds));

    return {
      fundsRequired: data.purchaseScenarioInfo.fundsRequired,
      numberOfMonths: data.purchaseScenarioInfo.numberOfMonths,
      purchaseDate: data.purchaseScenarioInfo.purchaseDate,
      leverageCash: data.purchaseScenarioInfo.leverageCash,
      shortfallAmount: data.purchaseScenarioInfo.shortfallAmount,
      leverageEquity: data.purchaseScenarioInfo.leverageEquity,
      summary: data.purchaseScenarioInfo.summary,
      isPassed: data.purchaseScenarioInfo.isPassed,
      isRedStatus: data.purchaseScenarioInfo.isRedStatus,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      isSold: data.isSold,
      equityRedrawLoanOptions: data.equityRedrawLoanOptions,
      loanTypeList: data.loanTypeValues.loanTypeList,
    };
  },
);
