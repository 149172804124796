import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../authentication/redux/selectors';
import {
  trackUserActivity,
  UserActivityReqType,
} from '../../client/redux/async_thunks';
import { setNavIndex } from '../../profile/redux/slice';

const RemiCtaButton = ({
  isHeader,
  style = {},
  inset,
}: {
  isHeader?: boolean;
  style?: any;
  inset?: boolean;
}) => {
  const REMI_ROUTE = '/remi';

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);

  const isBuildStep =
    user?.ctaPlanDetails?.goodFit === null || user?.ctaPlanDetails === null;

  const logCta = async (data: UserActivityReqType) => {
    try {
      if (user && !isBuildStep) {
        dispatch(trackUserActivity({ token: user?.token, data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCta = () => {
    dispatch(setNavIndex(2));

    logCta({
      type: 'Cta',
      ctaType: 'Remi',
      userId: user?.user_id || '',
    });

    if (user?.ctaPlanDetails?.goodFit === true) {
      navigate('/book-a-call');
    } else if (
      user?.ctaPlanDetails === null ||
      user?.ctaPlanDetails?.goodFit === null
    ) {
      navigate('/portfolio-growth-plan');
    }
  };

  const isVisible =
    isAgencyLeadView &&
    ((isHeader && location?.pathname?.includes(REMI_ROUTE)) || !isHeader);

  if (!isVisible || user?.ctaPlanDetails?.goodFit === false) {
    return null;
  }

  const insetColor = '#01938644';

  return (
    <div
      className="l-remi-cta"
      style={isHeader ? { marginRight: '30px' } : style}
    >
      <div
        className="l-remi-cta__actions"
        style={isHeader ? { marginBottom: 0 } : {}}
      >
        <div
          className="l-remi-cta__cta"
          style={
            isHeader
              ? {
                  padding: '0',
                  backgroundColor: '#effffe',
                  boxShadow: `inset 0 0 0 1px ${insetColor}`,
                }
              : {
                  border:
                    inset && isBuildStep ? `1px solid ${insetColor}` : 'none',
                }
          }
        >
          {isBuildStep && (
            <span
              style={
                isHeader
                  ? {
                      margin: '0 15px 0 20px',
                    }
                  : {}
              }
            >
              What&apos;s next?
            </span>
          )}
          <Button
            className={`l-remi-cta__cta-button${isHeader ? ' fancy' : ''}`}
            type="primary"
            shape="round"
            onClick={handleCta}
          >
            {isBuildStep ? (
              <>Build my Portfolio Plan</>
            ) : (
              <>Take the Next Step</>
            )}
            {!isHeader && <ArrowRightOutlined />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemiCtaButton;
