import { Button, Modal, notification } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  selectClientIp,
  selectUser,
} from '../../../features/authentication/redux/selectors';
import FormSelectField from '../../../foundation/components/form_select_field/FormSelectField';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { KeyValue } from '../../../foundation/types';
import { selectPlan } from '../../plan/redux/selectors';
import { acquireProperty, fetchPropertyAddresses } from '../redux/async_thunks';
import { selectPropertyPurchaseTypeValues } from '../redux/selectors';
import PropertyTypeValidationSchema from './validation_schema';

type Props = {
  closeModal: () => any;
};

const CreatePropertyModal = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const purchaseTypes = useSelector(selectPropertyPurchaseTypeValues);

  const clientIp = useSelector(selectClientIp);

  const modalOpts = {
    title: 'Create New Property',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);

      if (user?.token) {
        const data = {
          ...values,
          userId: user.user_id,
          planId: plan?.planId,
        };
        const response = await dispatch(
          acquireProperty({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();

        setIsLoading(false);
        closeModal();

        notification.success({
          message: 'Success!',
          description: 'Property created successfully',
        });

        if (response) {
          navigate(
            `/properties/${response[response.length - 1].propertyId}/info`,
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  // @ts-ignore
  const fetchAddresses = (name: string): Promise<KeyValue[]> => {
    if (user) {
      return fetchPropertyAddresses(user?.token, name, user.user_id, clientIp)
        .then((res) => {
          const addresses = res.addresses;
          const xyz: KeyValue[] = addresses
            ? addresses.map((item) => ({
                label: item,
                value: item,
              }))
            : [];
          return xyz;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    }
  };

  return (
    <Modal {...modalOpts} className="c-form-modal">
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          propertyName: '',
          purchaseType: undefined,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={PropertyTypeValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormSelectField
                label="Name"
                onChange={(v: string) => setFieldValue('propertyName', v)}
                value={values.propertyName}
                error={errors.propertyName}
                isDebounce
                fetchOptions={fetchAddresses}
              />
              <FormSelectField
                label="Purchase Type"
                onChange={(v: string) => setFieldValue('purchaseType', v)}
                value={values.purchaseType}
                error={errors.purchaseType}
                options={purchaseTypes}
              />

              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Create
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreatePropertyModal;
