import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';

export const fetchAgencies = createAsyncThunk(
  'enterprise/get-all-agencies',
  async (
    apiParams: {
      searchValue?: string;
      token: string;
      enterpriseId: string;
    },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    let EP = `${env_constants.PP_API_BASE_URL}/Enterprise/${apiParams.enterpriseId}/agencies`;

    if (apiParams.searchValue) {
      EP += `/${apiParams.searchValue}`;
    }

    const response = await axios.get(EP, options);

    const data = decryptData(response.data);
    return data.agencies;
  },
);

export const fetchAgency = createAsyncThunk(
  'agency/get',
  async (apiParams: { agencyId: string; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Agency/${apiParams.agencyId}`,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const createAgency = createAsyncThunk(
  'agency/create',
  async (apiParams: { token: string; data: any; picture: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      headers: {
        ...getBasicAuthAPIOptions(clientIp, apiParams.token).headers,
        'content-type': 'multipart/form-data',
      },
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Agency`,
      formData,
      options,
    );

    const data = decryptData(response.data);

    return data.agencyProfile;
  },
);

export const inviteUsers = createAsyncThunk(
  'admin/invite-users',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const encrypyedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Admin/invite`,
      encrypyedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const updateAgency = createAsyncThunk(
  'agency/update',
  async (apiParams: { token: string; data: any; picture: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      headers: {
        ...getBasicAuthAPIOptions(clientIp, apiParams.token).headers,
        'content-type': 'multipart/form-data',
      },
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.put(
      `${env_constants.PP_API_BASE_URL}/Agency`,
      formData,
      options,
    );

    const data = decryptData(response.data);

    return data.agencyProfile;
  },
);

export const deleteUser = createAsyncThunk(
  'agency/delete-user',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Agency/user`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const deleteAgency = createAsyncThunk(
  'agency/archive',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Agency`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);
