import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchClientData } from '../../features/authentication/redux/async_thunks';
import { selectClientIp } from '../../features/authentication/redux/selectors';
import {
  setClientIp,
  setMarketingCampaigns,
  setRoles,
  setStrongPassword,
} from '../../features/authentication/redux/slice';

export const useClientIP = () => {
  const dispatch = useDispatch();
  const clientIp = useSelector(selectClientIp);
  const navigate = useNavigate();

  const storedLeadLogin = sessionStorage.getItem('lead_login');

  const [isLoading, setIsLoading] = useState(true);
  const [attempts, setAttempts] = useState(0);
  const [error, setError] = useState(null);

  const getClientIp = async () => {
    try {
      // @ts-ignore
      const response = await dispatch(fetchClientData()).unwrap();

      if (response) {
        dispatch(setClientIp(response.ip));
        dispatch(setMarketingCampaigns(response.marketingCampaigns));
        dispatch(setRoles(response.roles));
        dispatch(setStrongPassword(!!response.strongPassword));
      }
    } catch (error) {
      setAttempts((prevAttempts) => prevAttempts + 1);
      setError(error.message || 'Failed to fetch client IP');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!clientIp) {
      if (storedLeadLogin) {
        const parsed = JSON.parse(storedLeadLogin);

        dispatch(setClientIp(parsed.clientIp));
        dispatch(setMarketingCampaigns(parsed.marketingCampaigns));
        dispatch(setRoles(parsed.roles));
        dispatch(setStrongPassword(parsed.strongPassword));

        setIsLoading(false);
      } else {
        getClientIp();
      }
    } else {
      setIsLoading(false);
    }
  }, [clientIp, dispatch, storedLeadLogin]);

  useEffect(() => {
    if (attempts >= 3) {
      navigate('/error');
    } else if (!isLoading && attempts > 0 && attempts < 3) {
      getClientIp();
    }
  }, [attempts, isLoading, navigate]);

  return { isLoading, error, clientIp };
};
