import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import { addItemToStorage } from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';
import {
  setFavoriteReports,
  setSharedReports,
} from '../../ai_report/redux/slice';
import { setClient } from '../../client/redux/slice';
import { setLastVisitedPlanId } from '../../plan/redux/slice';
import { setUser } from './slice';

type refreshTokenParamsType = {
  userId: string;
};

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (
    apiParams: { data: refreshTokenParamsType; token: string },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const roleIds = (thunkAPI.getState() as RootState).auth.roles;

    const encryptedData = encryptData(apiParams.data);

    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const headers = {
      ...options.headers,
    };

    const response = await axios.patch(
      `${env_constants.PP_API_BASE_URL}/Auth/refresh`,
      encryptedData,
      {
        headers,
      },
    );

    const data = decryptData(response.data);

    const userData = {
      user_id: data.userId,
      expiry: data.jwtToken.expiry,
      token: data.jwtToken.token,
      role_id: data.usersProfile.roleId,
      email: data.usersProfile.email,
      first_name: data.usersProfile.firstName,
      last_name: data.usersProfile.lastName,
      name: data.usersProfile.name,
      agency_id: data.usersProfile.agencyId,
      enterprise_id: data.usersProfile.enterpriseId,
      agency_name: data.usersProfile.agencyName,
      is_active: data.usersProfile.isActive,
      picture: data.usersProfile.userPicture,
      propertyStrategyLink: data.propertyStrategyLink,
      ctaPlanDetails: data.ctaPlanDetails,
      ctaUrls: data.ctaUrls,
    };

    // If client or lead account, set client details and plans
    if (
      (data?.usersProfile.roleId === roleIds?.client ||
        data?.usersProfile.roleId === roleIds?.agencyLead) &&
      data?.clientPlans
    ) {
      const clientData = {
        clientId: data.clientId,
        clientName: data.clientName,
        clientPhoto: data.clientPhoto,
        planIds: data.clientPlans,
      };

      thunkAPI.dispatch(setClient(clientData));
      addItemToStorage('client', JSON.stringify(clientData));
    }

    thunkAPI.dispatch(setLastVisitedPlanId(data.lastVisitedPlanId));

    if (data.favoriteReports?.length) {
      thunkAPI.dispatch(setFavoriteReports(data.favoriteReports));
    }

    if (data.sharedReports?.length) {
      thunkAPI.dispatch(setSharedReports(data.sharedReports));
    }

    return userData;
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
      const options = {
        ...getBasicAuthAPIOptions(clientIp, apiParams.token),
      };

      const encryptedData = encryptData(apiParams.data);
      const clientId = encryptData(`${window.navigator.userAgent}/${clientIp}`);

      const apiConfigs = {
        headers: {
          ...options.headers,
          clientid: clientId,
        },
      };

      const res = await axios.delete(
        `${env_constants.PP_API_BASE_URL}/Auth/logout`,
        {
          ...apiConfigs,
          data: encryptedData,
        },
      );

      return decryptData(res.data);
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchClientData = createAsyncThunk(
  'auth/clientData',
  async (apiParams: null, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${env_constants.PP_API_BASE_URL}/Auth/ip`,
        {
          headers: {
            'Content-type': 'application/json',
            clientid: encryptData(`${navigator.userAgent}`),
          },
        },
      );

      const decryptedDataResponse = decryptData(response.data);

      return decryptedDataResponse;
    } catch (error) {
      console.log(error);
      return rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const getInviteInfo: any = createAsyncThunk(
  'auth/get-invite-info',
  async (apiParams: { inviteId: string }, { getState }) => {
    let result;
    const clientIp = (getState() as RootState).auth.clientIp;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };

    await axios
      .get(
        `${env_constants.PP_API_BASE_URL}/Auth/${apiParams.inviteId}`,
        options,
      )
      .then((response) => {
        result = decryptData(response.data);
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);
        return Promise.reject(decrypted.responseCode);
      });

    return result;
  },
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (apiParams: { values: any; isFastTrackSignup: boolean }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const roleIds = (thunkAPI.getState() as RootState).auth.roles;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/join`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    // Logged in immediately after a successful signup
    if (apiParams.isFastTrackSignup) {
      const userData = {
        user_id: data.userId,
        expiry: data.jwtToken.expiry,
        token: data.jwtToken.token,
        role_id: data.usersProfile.roleId,
        email: data.usersProfile.email,
        first_name: data.usersProfile.firstName,
        last_name: data.usersProfile.lastName,
        name: data.usersProfile.name,
        agency_id: data.usersProfile.agencyId,
        enterprise_id: data.usersProfile.enterpriseId,
        agency_name: data.usersProfile.agencyName,
        is_active: data.usersProfile.isActive,
        picture: data.usersProfile.userPicture,
        propertyStrategyLink: data.propertyStrategyLink,
        ctaPlanDetails: data.ctaPlanDetails,
        ctaUrls: data.ctaUrls,
      };

      thunkAPI.dispatch(setUser(userData));

      addItemToStorage('user', JSON.stringify(userData));

      // If client or lead account, set client details and plans
      if (
        (data?.usersProfile.roleId === roleIds?.client ||
          data?.usersProfile.roleId === roleIds?.agencyLead) &&
        data?.clientPlans
      ) {
        const clientData = {
          clientId: data.clientId,
          clientName: data.clientName,
          clientPhoto: data.clientPhoto,
          planIds: data.clientPlans,
        };

        thunkAPI.dispatch(setClient(clientData));
        addItemToStorage('client', JSON.stringify(clientData));
      }
    }

    thunkAPI.dispatch(setLastVisitedPlanId(data.lastVisitedPlanId));

    return data;
  },
);

export const agencyLeadSignup = createAsyncThunk(
  'auth/agencyLeadSignup',
  async (apiParams: { data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const roleIds = (thunkAPI.getState() as RootState).auth.roles;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/agency-lead-signup`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    const userData = {
      user_id: data.userId,
      expiry: data.jwtToken.expiry,
      token: data.jwtToken.token,
      role_id: data.usersProfile.roleId,
      email: data.usersProfile.email,
      first_name: data.usersProfile.firstName,
      last_name: data.usersProfile.lastName,
      name: data.usersProfile.name,
      agency_id: data.usersProfile.agencyId,
      enterprise_id: data.usersProfile.enterpriseId,
      agency_name: data.usersProfile.agencyName,
      is_active: data.usersProfile.isActive,
      picture: data.usersProfile.userPicture,
      propertyStrategyLink: data.propertyStrategyLink,
      ctaPlanDetails: data.ctaPlanDetails,
      marketingCampaign: data.marketingCampaign,
      ctaUrls: data.ctaUrls,
    };

    thunkAPI.dispatch(setUser(userData));

    addItemToStorage('user', JSON.stringify(userData));

    // If client or lead account, set client details and plans
    if (
      (data?.usersProfile.roleId === roleIds?.client ||
        data?.usersProfile.roleId === roleIds?.agencyLead) &&
      data?.clientPlans
    ) {
      const clientData = {
        clientId: data.clientId,
        clientName: data.clientName,
        clientPhoto: data.clientPhoto,
        planIds: data.clientPlans,
      };

      thunkAPI.dispatch(setClient(clientData));
      addItemToStorage('client', JSON.stringify(clientData));
    }

    thunkAPI.dispatch(setLastVisitedPlanId(data.lastVisitedPlanId));

    return data;
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async (
    apiParams: { values: { email: string; passCode: string } },
    thunkAPI,
  ) => {
    try {
      const roleIds = (thunkAPI.getState() as RootState).auth.roles;
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;

      const options = {
        headers: {
          'Content-type': 'application/json',
          clientid: getSessionClientID(clientIp),
        },
      };
      const encryptedData = encryptData(apiParams.values);

      const response = await axios.post(
        `${env_constants.PP_API_BASE_URL}/Auth/login`,
        encryptedData,
        options,
      );

      const data = decryptData(response.data);

      const userData = {
        user_id: data.userId,
        expiry: data.jwtToken.expiry,
        token: data.jwtToken.token,
        role_id: data.usersProfile.roleId,
        email: data.usersProfile.email,
        first_name: data.usersProfile.firstName,
        last_name: data.usersProfile.lastName,
        name: data.usersProfile.name,
        agency_id: data.usersProfile.agencyId,
        enterprise_id: data.usersProfile.enterpriseId,
        agency_name: data.usersProfile.agencyName,
        is_active: data.usersProfile.isActive,
        picture: data.usersProfile.userPicture,
        propertyStrategyLink: data.propertyStrategyLink,
        ctaPlanDetails: data.ctaPlanDetails,
        ctaUrls: data.ctaUrls,
      };

      thunkAPI.dispatch(setUser(userData));

      addItemToStorage('user', JSON.stringify(userData));

      // If client or lead account, set client details and plans
      if (
        (data?.usersProfile.roleId === roleIds?.client ||
          data?.usersProfile.roleId === roleIds?.agencyLead) &&
        data?.clientPlans
      ) {
        const clientData = {
          clientId: data.clientId,
          clientName: data.clientName,
          clientPhoto: data.clientPhoto,
          planIds: data.clientPlans,
        };

        thunkAPI.dispatch(setClient(clientData));
        addItemToStorage('client', JSON.stringify(clientData));
      }

      thunkAPI.dispatch(setLastVisitedPlanId(data.lastVisitedPlanId));

      if (data.favoriteReports?.length) {
        thunkAPI.dispatch(setFavoriteReports(data.favoriteReports));
      }

      if (data.sharedReports?.length) {
        thunkAPI.dispatch(setSharedReports(data.sharedReports));
      }

      return data;
    } catch (error: any) {
      console.log(decryptData(error.response.data));
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgot-pass',
  async (apiParams: { data: { email: string } }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/forgot-password`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const verifyResetPassId = createAsyncThunk(
  'auth/verify-reset-id',
  async (apiParams: { resetId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Auth/forgot-password/${apiParams.resetId}`,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (
    apiParams: { data: { resetId: string; passCode: string } },
    thunkAPI,
  ) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;

      const options = {
        headers: {
          'Content-type': 'application/json',
          clientid: getSessionClientID(clientIp),
        },
      };
      const encryptedData = encryptData(apiParams.data);

      const response = await axios.put(
        `${env_constants.PP_API_BASE_URL}/Auth/forgot-password`,
        encryptedData,
        options,
      );

      const data = decryptData(response.data);

      return data;
    } catch (err) {
      console.log(err);
      console.log(decryptData(err));
    }
  },
);

export const deleteAccount = createAsyncThunk(
  'auth/delete',
  async (apiParams: { data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;

    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(clientIp),
      },
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Auth/delete`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);
