import { WistiaPlayer } from '@wistia/wistia-player-react';
import { Modal } from 'antd';
import React, { useRef } from 'react';

import { useViewport } from '../../../foundation/cutom_hooks/useViewport';

type LearnMoreVideoModalProps = {
  closeModal: () => void;
};

const LearnMoreVideoModal = ({ closeModal }: LearnMoreVideoModalProps) => {
  const LEARN_MORE_VIDEO_MEDIA_ID = 'cimcdks8um';

  const videoRef: any = useRef(null);
  const { isMobileViewport } = useViewport();

  const handleModalClose = () => {
    closeModal();
  };

  const modalOpts = {
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    footer: null,
    maskClosable: false,
    centered: true,
    width: 800,
    className: 'thin-padding-modal',
    title: (
      <>
        Understanding Your {isMobileViewport && <br />}
        Portfolio Growth Plan
      </>
    ),
  };

  return (
    <Modal {...modalOpts}>
      <div className="l-learn-more-video">
        <WistiaPlayer
          ref={videoRef}
          key={LEARN_MORE_VIDEO_MEDIA_ID}
          mediaId={LEARN_MORE_VIDEO_MEDIA_ID}
          playerColor="#00766c"
        />
      </div>
    </Modal>
  );
};

export default LearnMoreVideoModal;
