import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';
import {
  setPlanOffsetAccounts,
  setPlanRecords,
} from '../../property/redux/slice';
import { OffsetAccount } from './types';

export const fetchOffsetAccounts = createAsyncThunk(
  'offset_accounts/fetch-offset-accounts',
  async (apiParams: { token: string; planId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts/${apiParams.planId}`,
      options,
    );

    const data = decryptData(response.data);

    return data.offsetAccounts;
  },
);

const handleSettingOfPlanOffsets = (
  thunkAPI: any,
  data: any,
  setRecords = true,
) => {
  const responseData = {
    ...data,
  };

  thunkAPI.dispatch(
    setPlanOffsetAccounts(responseData.offsetAccountTransactions),
  );

  if (setRecords) {
    thunkAPI.dispatch(setPlanRecords(responseData.planRecords));
  }

  delete responseData.offsetAccountTransactions;

  return responseData;
};

export type CreateNewOffsetAccountReqBody = {
  planId: string;
  transactionDate: any;
  userId: string;
};

export const createNewOffsetAccount = createAsyncThunk(
  'offset_accounts/create-offset-account',
  async (
    apiParams: {
      token: string;
      values: CreateNewOffsetAccountReqBody;
    },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return handleSettingOfPlanOffsets(thunkAPI, data, true);
  },
);

export const fetchOffsetAccount = createAsyncThunk(
  'offset_accounts/fetch-offset-account',
  async (
    apiParams: {
      token: string;
      planId: string;
      offsetAccountId: string;
    },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts/${apiParams.planId}/${apiParams.offsetAccountId}`,
      options,
    );

    const data = decryptData(response.data);

    return handleSettingOfPlanOffsets(thunkAPI, data, false);
  },
);

export const fetchOffsetAccountInfoByDate = createAsyncThunk(
  'offset_accounts/fetch-offset-account-info-by-date',
  async (
    apiParams: {
      token: string;
      planId: string;
      offsetAccountId: string;
      date: string;
    },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts/${apiParams.planId}/${apiParams.offsetAccountId}/${apiParams.date}`,
      options,
    );

    const data = decryptData(response.data);

    return handleSettingOfPlanOffsets(thunkAPI, data, false);
  },
);

export const deleteOffsetAccount = createAsyncThunk(
  'offset_accounts/delete-offset-account',
  async (
    apiParams: {
      token: string;
      planId: string;
      offsetAccountId: string;
      userId: string;
    },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData({
      planId: apiParams.planId,
      offsetAccountId: apiParams.offsetAccountId,
      userId: apiParams.userId,
    });

    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return handleSettingOfPlanOffsets(thunkAPI, data, true);
  },
);

export type UpdateOffsetAccountBodyType = {
  planId: string;
  offsetAccountId: string;
  transactionDate: string;
  isActive: boolean;
  savings?: {
    availableAmount: number;
    newAvailableAmount: number;
  };
  portfolioCashFlow?: {
    availableAmount: number;
    newAvailableAmount: number;
  };
  propertyTransactions: OffsetAccount[];
  userId: string;
};

export const updateOffsetAccount = createAsyncThunk(
  'offset_accounts/update-offset-account',
  async (
    apiParams: { token: string; values: UpdateOffsetAccountBodyType },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.put(
      `${env_constants.PP_API_BASE_URL}/OffsetAccounts`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return handleSettingOfPlanOffsets(thunkAPI, data);
  },
);
