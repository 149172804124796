import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchMonthlyModelingByDataView,
  updateMonthlyModelingGrossIncome,
  updateMonthlyModelingInterestRate,
  updateMonthlyModelingPurchaseValue,
} from '../../monthly_modeling/redux/async_thunks';
import {
  fetchPropertyNotes,
  updatePropertyNotes,
} from '../../notes/redux/async_thunks';
import { OffsetAccountSummary } from '../../offset_account/redux/types';
import {
  fetchPurchaseScenario,
  updateLeverageCash,
  updateLeverageEquity,
  updatePurchaseScenarioMonth,
} from '../../purchase_scenario/redux/async_thunks';
import {
  acquireProperty,
  duplicateProperty,
  fetchAnnualFigures,
  fetchFormValues,
  fetchPerformanceProjectionGraphData,
  fetchPlanProperties,
  fetchPropertyInfo,
  fetchPropertyPurchaseInfo,
  fetchSellValues,
  purchaseProperty,
  sellProperty,
  setPropertyInfo,
  undoSell,
  updateAnnualFiguresCashIn,
  updateAnnualFiguresCashOut,
  updateAnnualFiguresGrowthProjectionsBulk,
  updateAnnualFiguresInterestRateBulk,
  updateAnnualFiguresPerformanceDetail,
  updatePurchaseDetails,
  updatePurchaseDetailsStatus,
  updatePurchaseSummary,
} from './async_thunks';
import { PlanRecords, PropertyListItem, PropertyState } from './types';

const initialState: PropertyState = {
  list: undefined,
  offset_accounts: undefined,
  planRecords: undefined,
  form_inputs: undefined,
  property: undefined,
  purchase: undefined,
  annual_figures: undefined,
  monthly_modeling: undefined,
  cashflow_projection: undefined,
  equity_forecast: undefined,
  purchase_scenario: undefined,
  property_notes: undefined,
  selected_property_id: undefined,
};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPlanProperties: (
      state,
      action: PayloadAction<PropertyListItem[] | undefined>,
    ) => {
      state.list = action.payload;
    },
    setCompletePlanProperties: (state, action) => {
      state.list = action.payload.properties;
      state.offset_accounts = action.payload.offset_accounts;
      state.planRecords = action.payload.planRecords;
    },
    setSelectedPropertyId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selected_property_id = action.payload;
    },
    setPlanOffsetAccounts: (
      state,
      action: PayloadAction<OffsetAccountSummary[] | undefined>,
    ) => {
      state.offset_accounts = action.payload;
    },
    setPlanRecords: (
      state,
      action: PayloadAction<PlanRecords[] | undefined>,
    ) => {
      state.planRecords = action.payload;
    },
    resetAnnualFigures: (state) => {
      state.annual_figures = undefined;
    },
    resetMonthlyModeling: (state) => {
      state.monthly_modeling = undefined;
    },
    resetPurchase: (state) => {
      state.purchase = undefined;
    },
    resetCashflowProjection: (state) => {
      state.cashflow_projection = undefined;
    },
    resetEequityForecast: (state) => {
      state.equity_forecast = undefined;
    },
    resetPlanProperties: (state) => {
      state.list = undefined;
      state.offset_accounts = undefined;
      state.planRecords = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlanProperties.fulfilled, (state, action) => {
        state.list = action.payload.properties;
        state.offset_accounts = action.payload.offset_accounts;
        state.planRecords = action.payload.planRecords;
      })
      .addCase(acquireProperty.fulfilled, (state, action) => {
        state.list = action.payload.properties;
        state.offset_accounts = action.payload.offset_accounts;
        state.planRecords = action.payload.planRecords;
      })
      .addCase(fetchPropertyInfo.fulfilled, (state, action) => {
        state.property = action.payload;
      })
      .addCase(duplicateProperty.fulfilled, (state, action) => {
        state.property = action.payload;
      })
      .addCase(setPropertyInfo.fulfilled, (state, action) => {
        state.property = action.payload;
      })
      .addCase(sellProperty.fulfilled, (state, action) => {
        state.property = action.payload;
      })
      .addCase(undoSell.fulfilled, (state, action) => {
        state.property = action.payload;
      })
      .addCase(fetchSellValues.fulfilled, (state, action) => {
        if (state.property) {
          state.property.sellValues = action.payload;
        }
      })
      .addCase(fetchPurchaseScenario.fulfilled, (state, action) => {
        state.purchase_scenario = action.payload;
      })
      .addCase(updateLeverageEquity.fulfilled, (state, action) => {
        state.purchase_scenario = action.payload;
      })
      .addCase(updatePurchaseScenarioMonth.fulfilled, (state, action) => {
        state.purchase_scenario = action.payload;
      })
      .addCase(updateLeverageCash.fulfilled, (state, action) => {
        state.purchase_scenario = action.payload;
      })
      .addCase(fetchPropertyPurchaseInfo.fulfilled, (state, action) => {
        state.purchase = action.payload;
      })
      .addCase(updatePurchaseSummary.fulfilled, (state, action) => {
        state.purchase = action.payload;
      })
      .addCase(updatePurchaseDetails.fulfilled, (state, action) => {
        state.purchase = action.payload;
      })
      .addCase(updatePurchaseDetailsStatus.fulfilled, (state, action) => {
        state.purchase = action.payload;
      })
      .addCase(purchaseProperty.fulfilled, (state, action) => {
        state.purchase = action.payload;
      })
      .addCase(fetchAnnualFigures.fulfilled, (state, action) => {
        state.annual_figures = action.payload;
      })
      .addCase(updateAnnualFiguresCashIn.fulfilled, (state, action) => {
        state.annual_figures = action.payload;
      })
      .addCase(updateAnnualFiguresCashOut.fulfilled, (state, action) => {
        state.annual_figures = action.payload;
      })
      .addCase(fetchPropertyNotes.fulfilled, (state, action) => {
        state.property_notes = action.payload;
      })
      .addCase(updatePropertyNotes.fulfilled, (state, action) => {
        state.property_notes = action.payload;
      })
      .addCase(
        updateMonthlyModelingPurchaseValue.fulfilled,
        (state, action) => {
          state.monthly_modeling = action.payload;
        },
      )
      .addCase(updateMonthlyModelingGrossIncome.fulfilled, (state, action) => {
        state.monthly_modeling = action.payload;
      })
      .addCase(updateMonthlyModelingInterestRate.fulfilled, (state, action) => {
        state.monthly_modeling = action.payload;
      })
      .addCase(fetchMonthlyModelingByDataView.fulfilled, (state, action) => {
        state.monthly_modeling = action.payload;
      })
      .addCase(
        updateAnnualFiguresPerformanceDetail.fulfilled,
        (state, action) => {
          state.annual_figures = action.payload;
        },
      )
      .addCase(
        updateAnnualFiguresGrowthProjectionsBulk.fulfilled,
        (state, action) => {
          state.annual_figures = action.payload;
        },
      )
      .addCase(
        updateAnnualFiguresInterestRateBulk.fulfilled,
        (state, action) => {
          state.annual_figures = action.payload;
        },
      )
      .addCase(
        fetchPerformanceProjectionGraphData.fulfilled,
        (state, action) => {
          if (action.payload.graphType === 'EquityForecast') {
            state.equity_forecast = action.payload.equityForcast;
          } else {
            state.cashflow_projection = action.payload.cashflowProjection;
          }
        },
      )
      .addCase(fetchFormValues.fulfilled, (state, action) => {
        state.form_inputs = action.payload;
      });
  },
});

export const {
  setPlanProperties,
  setSelectedPropertyId,
  resetAnnualFigures,
  resetMonthlyModeling,
  resetPurchase,
  resetCashflowProjection,
  resetEequityForecast,
  setPlanOffsetAccounts,
  setPlanRecords,
  resetPlanProperties,
  setCompletePlanProperties,
} = propertySlice.actions;

export default propertySlice.reducer;
