import { useMediaQuery } from 'react-responsive';

export const useViewport = () => {
  const isMobileViewport = useMediaQuery({ maxWidth: 767 });
  const isTabletViewport = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isLargeTabletViewport = useMediaQuery({
    minWidth: 992,
    maxWidth: 1199,
  });
  const isLargeDesktopViewport = useMediaQuery({
    minWidth: 1600,
    maxWidth: 1999,
  });

  const isVeryLargeDesktopViewport = useMediaQuery({
    minWidth: 2000,
  });

  const isLargeOrVeryLargeDesktopViewport =
    isLargeDesktopViewport || isVeryLargeDesktopViewport;

  const isDesktopViewport = !(
    isMobileViewport ||
    isTabletViewport ||
    isLargeTabletViewport
  );

  return {
    isMobileViewport,
    isTabletViewport,
    isLargeTabletViewport,
    isDesktopViewport,
    isLargeDesktopViewport,
    isVeryLargeDesktopViewport,
    isLargeOrVeryLargeDesktopViewport,
  };
};
