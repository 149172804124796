import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../../internals/env/env_constants.json';
import { RootState } from '../../../../store';

export const fetchRiskProfileData = createAsyncThunk(
  'risk_profile/get-data',
  async (apiParams: { token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/risk-profile`,
      options,
    );
    const data = decryptData(response.data);

    return data;
  },
);

export const updateRiskProfile = createAsyncThunk(
  'risk_profile/update',
  async (apiParams: { data: object; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/risk-profile`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);
