import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';

export const fetchEnterprises = createAsyncThunk(
  'enterprise/get-all',
  async (apiParams: { searchValue?: string; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    let EP = `${env_constants.PP_API_BASE_URL}/Super`;

    if (apiParams.searchValue) {
      EP += `/${apiParams.searchValue}`;
    }

    const response = await axios.get(EP, options);

    const data = decryptData(response.data);
    return data.enterprises;
  },
);

export const fetchEnterprise = createAsyncThunk(
  'enterprise/get',
  async (apiParams: { enterpriseId: string; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Enterprise/${apiParams.enterpriseId}`,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const createEnterprise = createAsyncThunk(
  'enterprise/create',
  async (apiParams: { token: string; data: any; picture: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      headers: {
        ...getBasicAuthAPIOptions(clientIp, apiParams.token).headers,
        'content-type': 'multipart/form-data',
      },
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Enterprise`,
      formData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const updateEnterprise = createAsyncThunk(
  'enterprise/update',
  async (apiParams: { token: string; data: any; picture: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      headers: {
        ...getBasicAuthAPIOptions(clientIp, apiParams.token).headers,
        'content-type': 'multipart/form-data',
      },
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.put(
      `${env_constants.PP_API_BASE_URL}/Enterprise`,
      formData,
      options,
    );

    const data = decryptData(response.data);

    return data.enterpriseProfile;
  },
);

export const inviteEnterpriseAdmins = createAsyncThunk(
  'enterprise/invite-admin',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const encrypyedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Super/invite`,
      encrypyedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const deleteEnterpriseAdmin = createAsyncThunk(
  'enterprise/delete-user',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Enterprise/user`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const toggleEnterpriseState = createAsyncThunk(
  'super/toggle-enterprise-state',
  async (apiParams: { token: any; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const encrypyedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Super/archive`,
      encrypyedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);
