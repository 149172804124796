import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';
import { setUser } from '../../authentication/redux/slice';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetch-data',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    let data;

    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const state: any = thunkAPI.getState();
    const userData = state.auth.user;

    const encryptedValues = encryptData(apiParams.values);

    await axios
      .post(
        `${env_constants.PP_API_BASE_URL}/Dashboard`,
        encryptedValues,
        options,
      )
      .then((response) => {
        if (response?.status === 206) {
          data = null;
        } else {
          data = decryptData(response.data);

          thunkAPI.dispatch(
            setUser({
              ...userData,
              ctaPlanDetails: data.ctaPlanDetails || null,
            }),
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return data;
  },
);

export const sharePlanDashboard = createAsyncThunk(
  'dashboard/share',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };
    const encryptedValues = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Dashboard/share`,
      encryptedValues,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);
