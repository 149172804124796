import React, { useState } from 'react';

import VideoPlay from '../../../foundation/assets/images/LearnMore.png';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import LearnMoreVideoModal from '../modals/LearnMoreVideoModal';

const LearnMore = () => {
  const { isDesktopViewport } = useViewport();

  const [isLearnMoreVideoModalVisible, setIsLearnMoreVideoModalVisible] =
    useState(false);

  const onLeanrMoreModalOpen = () => {
    setIsLearnMoreVideoModalVisible(true);
  };

  const onLearnMoreVideoModalClose = () => {
    setIsLearnMoreVideoModalVisible(false);
  };

  return (
    <>
      {isLearnMoreVideoModalVisible && (
        <LearnMoreVideoModal closeModal={onLearnMoreVideoModalClose} />
      )}
      <div
        className={`l-learn-more${isDesktopViewport ? '' : ' l-learn-more--mobile'}`}
      >
        <button
          onClick={onLeanrMoreModalOpen}
          className="l-learn-more__video-btn"
        >
          <img src={VideoPlay} alt="Play video" />
        </button>
        <div>
          <p>
            <button onClick={onLeanrMoreModalOpen}>
              Discover the Power of Your Plan
            </button>
          </p>
          <p>Check out the benefits of your plan and how it all works</p>
        </div>
      </div>
    </>
  );
};

export default LearnMore;
