import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';
import { OffsetAccountSummary } from '../../offset_account/redux/types';
import { setPlanProperties, setPlanRecords } from './slice';
import { PropertyListItem } from './types';

export const getModifiedPropertiesData = (
  planPropertyIds: PropertyListItem[],
) => {
  let index = 0;
  const data = [];
  for (const item of planPropertyIds) {
    index++;
    data.push({
      ...item,
      headerTitle: `Property ${String(index).padStart(2, '0')}`,
    });
  }
  return data;
};

export const getModifiedOffsetAccountData = (
  offsetAccounts: OffsetAccountSummary[],
) => {
  let index = 0;
  const data = [];
  for (const item of offsetAccounts) {
    index++;
    data.push({
      ...item,
      headerTitle: `Offset Account ${String(index).padStart(2, '0')}`,
    });
  }
  return data;
};

const getParsedPropertyData = (data: any) => {
  return {
    propertyId: data.propertyId,
    propertyName: data.propertyName,
    isActive: data.isActive,
    isPassed: data.isPassed,
    previousUnderContractDate: data.previousUnderContractDate,
    contractDate: data.contractDate,
    purchaseType: data.purchaseType,
    clientRefinancingMaxLvr: data.clientRefinancingMaxLvr,
    maxClientPurchaseLvr: data.maxClientPurchaseLvr,
    apexInputs: {
      ...data.apexInputs,
      propertyName: data.propertyName,
      isActive: data.isActive,
    },
    sellValues: data.sellValues,
    isSold: data.isSold,
  };
};

export const fetchPlanProperties = createAsyncThunk(
  'property/fetch-plan-properties',
  async (apiParams: { token: string; planId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Plan/properties/${apiParams.planId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      properties: getModifiedPropertiesData(data.planPropertyIds),
      offset_accounts: getModifiedOffsetAccountData(data.offsetAccounts),
      planRecords: data.planRecords,
    };
  },
);

export const fetchFormValues = createAsyncThunk(
  'property/form-values',
  async (apiParams: { token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Values`,
      options,
    );

    const data = decryptData(response.data);

    return {
      apexPropertyTypeValues: data.apexPropertyTypeValues,
      loanTypeList: data.apexLoanTypeValues.loanTypeList,
      daysUnconditional: data.daysUnconditional,
      daysSettlement: data.daysSettlement,
      startMonth: data.startMonth,
      performanceTimelineTypes: data.performanceTimelineTypes,
      paymentStatusTypeValues: data.paymentStatusTypeValues.paymentStatus,
      propertyPurchaseType: data.propertyPurchaseType,
      offsetAccountsTransactionValues: data.offsetAccountsTransactionValues,
      focusValues: data.focusValues,
      costByStateValues: data.costByStateValues,
      sellFeatureCGTDiscountOptions: data.sellFeatureCgtDiscountOptions,
      planInputsValues: data.planInputsValues,
      suburbScoreFilters: data.suburbScoreFilters,
    };
  },
);

export const fetchPropertyInfo = createAsyncThunk(
  'property/fetch-property-info',
  async (apiParams: { token: string; propertyId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Info/${apiParams.propertyId}`,
      options,
    );

    const data = decryptData(response.data);

    console.log(getParsedPropertyData(data));
    return getParsedPropertyData(data);
  },
);

export const acquireProperty = createAsyncThunk(
  'property/acquire-property',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Plan/add-property`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      properties: getModifiedPropertiesData(data.planPropertyIds),
      offset_accounts: getModifiedOffsetAccountData(data.offsetAccounts),
      planRecords: data.planRecords,
    };
  },
);

export const setPropertyInfo = createAsyncThunk(
  'property/set-property-info',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Info`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...getParsedPropertyData(data),
      previousUnderContractDate:
        data.previousUnderContractDate === ''
          ? undefined
          : data.previousUnderContractDate,
    };
  },
);

export const fetchPropertyPurchaseInfo = createAsyncThunk(
  'property/fetch-property-purchase',
  async (apiParams: { token: string; propertyId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/CashRequiredEstimate/${apiParams.propertyId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      contractDate: data.contractDate,
      isSold: data.isSold,
      cashRequiredEstimateInfo: data.cashRequiredEstimateInfo,
    };
  },
);

export const updatePurchaseSummary = createAsyncThunk(
  'property/update-purchase-summary',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/CashRequiredEstimate/payment-category`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      contractDate: data.contractDate,
      isSold: data.isSold,
      cashRequiredEstimateInfo: data.cashRequiredEstimateInfo,
    };
  },
);

export const updatePurchaseDetails = createAsyncThunk(
  'property/update-purchase-details',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/CashRequiredEstimate/payment-details`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      contractDate: data.contractDate,
      isSold: data.isSold,
      cashRequiredEstimateInfo: data.cashRequiredEstimateInfo,
    };
  },
);

export const updatePurchaseDetailsStatus = createAsyncThunk(
  'property/update-purchase-details-status',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/CashRequiredEstimate/payment-status`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      contractDate: data.contractDate,
      isSold: data.isSold,
      cashRequiredEstimateInfo: data.cashRequiredEstimateInfo,
    };
  },
);

export const purchaseProperty = createAsyncThunk(
  'property/purchase-property',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/PropertyDetails/property-purchase`,
      apiParams.values,
      options,
    );

    const { data } = response;

    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      contractDate: data.contractDate,
      isSold: data.isSold,
      cashRequiredEstimateInfo: data.cashRequiredEstimateInfo,
    };
  },
);

export const fetchAnnualFigures = createAsyncThunk(
  'property/fetch-annual-figures',
  async (apiParams: { token: string; propertyId: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/${apiParams.propertyId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const updateAnnualFiguresCashIn = createAsyncThunk(
  'property/update-annual-figures-cash-in',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/cash-in`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const updateAnnualFiguresCashOut = createAsyncThunk(
  'property/update-annual-figures-cash-out',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/cash-out`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const updateAnnualFiguresPerformanceDetail = createAsyncThunk(
  'property/update-annual-figures-performance-details',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };
    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/performance-details`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const updateAnnualFiguresGrowthProjectionsBulk = createAsyncThunk(
  'property/update-annual-figures-growth-bulk',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };
    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/annual-growth-batch`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const updateAnnualFiguresInterestRateBulk = createAsyncThunk(
  'property/update-annual-figures-interest-rate-bulk',
  async (apiParams: { token: string; values: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };
    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/AnnualFigures/interest-rate-batch`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      ...data.annualFigures,
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
    };
  },
);

export const fetchPerformanceProjectionGraphData = createAsyncThunk(
  'property/get-graph-data',
  async (
    apiParams: {
      token: string;
      data: any;
      dataView?: 'm' | 'a' | 'q';
    },
    thunkAPI,
  ) => {
    const state: any = thunkAPI.getState();

    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Graph/performance-projections`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    if (apiParams.data.graphType === 'EquityForecast') {
      const reduxGraphData: any = {};
      if (apiParams.dataView === 'm') {
        reduxGraphData.monthlyGraph = data.graphs;
        reduxGraphData.quaterlyGraph =
          state.property.equity_forecast?.quaterlyGraph;
        reduxGraphData.yearlyGraph =
          state.property.equity_forecast?.yearlyGraph;
      } else if (apiParams.dataView === 'q') {
        reduxGraphData.monthlyGraph =
          state.property.equity_forecast?.monthlyGraph;
        reduxGraphData.quaterlyGraph = data.graphs;
        reduxGraphData.yearlyGraph =
          state.property.equity_forecast?.yearlyGraph;
      } else {
        reduxGraphData.monthlyGraph =
          state.property.equity_forecast?.monthlyGraph;
        reduxGraphData.quaterlyGraph =
          state.property.equity_forecast?.quaterlyGraph;
        reduxGraphData.yearlyGraph = data.graphs;
      }

      return {
        equityForcast: {
          equityDetails: data.performanceDetails,
          propertyId: data.propertyId,
          propertyName: data.propertyName,
          ...reduxGraphData,
        },
        graphType: apiParams.data.graphType,
      };
    } else {
      const reduxGraphData: any = {};

      if (apiParams.dataView === 'm') {
        reduxGraphData.monthlyGraph = data.graphs;
        reduxGraphData.quaterlyGraph =
          state.property.cashflow_projection?.quaterlyGraph;
        reduxGraphData.yearlyGraph =
          state.property.cashflow_projection?.yearlyGraph;
      } else if (apiParams.dataView === 'q') {
        reduxGraphData.monthlyGraph =
          state.property.cashflow_projection?.monthlyGraph;
        reduxGraphData.quaterlyGraph = data.graphs;
        reduxGraphData.yearlyGraph =
          state.property.cashflow_projection?.yearlyGraph;
      } else {
        reduxGraphData.monthlyGraph =
          state.property.cashflow_projection?.monthlyGraph;
        reduxGraphData.quaterlyGraph =
          state.property.cashflow_projection?.quaterlyGraph;
        reduxGraphData.yearlyGraph = data.graphs;
      }

      return {
        cashflowProjection: {
          performanceDetails: data.performanceDetails,
          propertyId: data.propertyId,
          propertyName: data.propertyName,
          ...reduxGraphData,
        },
        graphType: apiParams.data.graphType,
      };
    }
  },
);

export const deleteProperty = createAsyncThunk(
  'property/delete',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Info/delete`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return {
      propertyIds: getModifiedPropertiesData(data.planPropertyIds),
      latestPropertyId: data.latestPropertyId,
      planRecords: data.planRecords,
    };
  },
);

export const fetchPropertyAddresses = async (
  token: string,
  searchKey: string,
  userId: string,
  clientIp: string | undefined,
) => {
  const options = {
    ...getBasicAuthAPIOptions(clientIp, token),
  };

  const response: any = await axios.post(
    `${env_constants.PP_API_BASE_URL}/Address`,
    encryptData({ userId, addressInput: searchKey }),
    options,
  );

  const data = decryptData(response.data);
  return data;
};

export const fetchSellValues = createAsyncThunk(
  'property/fetch-sell-values',
  async (
    apiParams: {
      token: string;
      propertyId: string;
      selldate: string;
      discount: number;
    },
    thunkAPI,
  ) => {
    const { token, propertyId, selldate, discount } = apiParams;

    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Info/sell/${propertyId}/${selldate}/${discount}`,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const sellProperty = createAsyncThunk(
  'property/sell-property',
  async (apiParams: { values: any; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Info/sell`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return getParsedPropertyData(data);
  },
);

export const undoSell = createAsyncThunk(
  'property/undo-sell',
  async (apiParams: { values: any; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.patch(
      `${env_constants.PP_API_BASE_URL}/Info/undo-sell`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return getParsedPropertyData(data);
  },
);

export type DuplicatePropertyReqBody = {
  propertyId: string;
  userId: string;
};

export const duplicateProperty = createAsyncThunk(
  'property/duplicate',
  async (
    apiParams: { token: string; data: DuplicatePropertyReqBody },
    thunkAPI,
  ) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Info/duplicate`,
      encryptedData,
      {
        ...options,
      },
    );

    const data = decryptData(response.data);

    const responseData: any = {
      ...data,
    };

    thunkAPI.dispatch(
      setPlanProperties(getModifiedPropertiesData(data.planPropertyIds)),
    );

    thunkAPI.dispatch(setPlanRecords(data.planRecords));

    delete responseData.planPropertyIds;
    delete responseData.planRecords;
    delete responseData.responseCode;
    delete responseData.responseMessage;
    delete responseData.warningMessage;

    return responseData;
  },
);
