import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import FullPageLoader from '../full_page_loader/FullPageLoader.index';

// Display when the route doesn't exists.
export default (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  /**
   * This is needed so that we can handle both the NotFound as well as our current
   * dynamic routing logic because we are rendering different routes for different roles
   * and sometimes re-rendering is causing the recalculation of routes
   */
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  });

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate('/');
          }}
        >
          Return Home
        </Button>
      }
    />
  );
};
