import './styles/styles.scss';

import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppLayout from './features/layout/Layout';
import RouteManager from './features/startup/RouteManager';
import Startup from './features/startup/Startup';
import FullPageLoader from './foundation/components/full_page_loader/FullPageLoader.index';
import { injectStore } from './foundation/config/axios';
import { useClientIP } from './foundation/cutom_hooks/useClientIP';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

injectStore(store);

const Root = () => {
  /**
   * We need client ip on almost every route public and private both. That's why fetching it here.
   */
  const { isLoading } = useClientIP();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b2a3',
        },
      }}
    >
      <Startup />
      <AppLayout>
        <RouteManager />
      </AppLayout>
    </ConfigProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
