import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../authentication/redux/selectors';
import {
  trackUserActivity,
  UserActivityReqType,
} from '../../client/redux/async_thunks';
import { selectPlan } from '../../plan/redux/selectors';

const CtaButton = ({ isHeader }: { isHeader?: boolean }) => {
  const BOOKING_ROUTE = '/book-a-call';

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);
  const plan = useSelector(selectPlan);

  const isVisible =
    isAgencyLeadView &&
    ((isHeader &&
      plan &&
      plan.planName &&
      location?.pathname !== BOOKING_ROUTE) ||
      !isHeader);

  const logCta = async (data: UserActivityReqType) => {
    try {
      if (user) {
        dispatch(trackUserActivity({ token: user?.token, data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCta = () => {
    logCta({
      type: 'Cta',
      ctaType: 'Pgp',
      userId: user?.user_id || '',
    });

    navigate('/book-a-call');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {user?.ctaPlanDetails?.goodFit && (
        <div
          className="l-lead-cta"
          style={isHeader ? { position: 'unset', marginRight: '30px' } : {}}
        >
          <Button
            style={
              isHeader
                ? {
                    height: '44px',
                    border: 'none',
                    width: '190px',
                  }
                : {}
            }
            className="l-lead-cta__button"
            type="primary"
            shape="round"
            onClick={handleCta}
          >
            Take the Next Step
            {!isHeader && <ArrowRightOutlined />}
          </Button>
        </div>
      )}
    </>
  );
};

export default CtaButton;
