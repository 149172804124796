import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';

import reducers from './reducers';

export const history = createBrowserHistory();

const router = routerMiddleware(history);

export const store = configureStore({
  reducer: reducers(history),
  middleware: (getDefaultMiddleware) => {
    if (window.location.hostname.includes('localhost')) {
      // @ts-ignore
      return getDefaultMiddleware().concat(router, logger);
    }

    return getDefaultMiddleware().concat(router);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
